<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__center">
      <div class="container__icon">
        <svg
          width="80" height="80"
          style="min-width: 80px; min-height: 80px;"
          viewBox="0 0 80 80" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20 0H40V20L20 40H0V20L20 0Z" fill="#DA2828" fill-opacity="0.2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M40 40H20L0 60V80H20L40 60V80H60L80 60V40H60L80 20V0H60L40 20V40ZM40 40H60L40 60V40Z" fill="#DA2828" fill-opacity="0.2"/>
          <g clip-path="url(#clip0_2718_25946)">
            <path d="M60 24.5834C60 22.0551 57.9449 20 55.4166 20H24.5834C22.0551 20 20 22.0551 20 24.5834V55.4166C20 57.9449 22.0551 60 24.5834 60H55.4166C57.9449 60 60 57.9449 60 55.4166V24.5834Z" fill="#DA2828"/>
            <path d="M32 32L48 48M48 32L32 48" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_2718_25946">
              <rect width="40" height="40" fill="white" transform="translate(20 20)"/>
            </clipPath>
          </defs>
        </svg>
      </div>

      <p>
        {{ $t('components.ui.pages.pop-up.checkout-time-limit.text') }}
      </p>

      <div class="timer">
        00:{{ _timer }}
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'

export default {
  components: {
    ClosePopUpButton
  },
  data () {
    return {
      interval: null,
      intervalCount: 0
    }
  },
  computed: {
    _value () {
      return this.$store.state.popUp.active
    },
    _timer () {
      const calculation = this._value.seconds - this.intervalCount
      return calculation <= 9 ? `0${calculation}` : calculation
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      this.intervalCount += 1

      if (this._timer <= 0) {
        this.$pop.down()
      }
    }, 1000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 355px;
  padding: 30px 20px;
  background: white;
  border-radius: 15px;
  overflow: hidden;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__center {
    display: flex;
    flex-direction: column;
    align-items: center;

    .container__icon {
      display: flex;
      justify-content: center;
    }

    p {
      margin-top: 30px;
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      color: rgb(var(--color-dark));
      font-family: SemiBold;
    }

    .timer {
      margin-top: 20px;
      padding: 6px 16px;
      background: #C9D3F5;
      border-radius: 20px;
      font-size: 18px;
      line-height: 100%;
      color: #2F57E9;
      font-family: SemiBold;
    }
  }
}
</style>
